html {
    height: 100%;
}

body {
    background: url(images/room.jpg) fixed;
    color: #666;
    font: 14pt Verdana, Geneva, sans-serif;
    hyphens: auto;
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
}

/*##### content wrapper #####*/

#content {
    margin: 10px auto 20px;
    padding: 10px 40px 20px;
    width: 1200px;
}

/*##### header #####*/

#header {
    margin: 5px 0;
    position: relative;
}

/*##### table design #####*/

table {
    border-spacing: 0;
    font-size: 8pt;
    text-align: left;
    width: 1200px;
    position: relative;
}

caption {
    background: linear-gradient(to top, #fbfbfb, #F4F4F4 90%) 0 0 repeat scroll transparent;
    border: #cdcdcd solid 1px;
    border-bottom: none;
    padding: 10px 4px;
    text-align: center;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
}

button.link {
    background: none;
    border: none;
    font-size: 9pt;
    color: #666;
    padding: 0;
    cursor: pointer;
}

button.link:hover {
    color: #2b477d;
}

button.active {
    color: #2b477d;
    font-weight: bold;
}

.nav {
    position: absolute;
    padding-left: 6px;
    opacity: 1;
    top: 10px;
}

.nav img {
    padding: 1px;
    margin-right: 5px;
}

a {
    opacity: .3;
}

a:hover {
    opacity: .8;
}

.active_nav {
    opacity: .8;
}

caption > p {
    display: inline-block;
}

caption > p > span:first-child {
    font-size: 14pt;
    display: block;
    padding: 0 100px;
    border-bottom: 1px solid #888;
}

caption > p > span:last-child {
    display: block;
    font-size: 9pt;
    margin-top: 2px;
}

tr > td {
    color: #666;
    width: 30px;
    max-width: 30px;
    max-height: 45px;
}

thead, tfoot {
    box-shadow: 0 4px 4px -3px #D0D0D0;
    text-align: center;
}

thead th, tfoot th {
    text-align: right;
    border-left: 1px solid #cdcdcd;
    border-bottom: 1px solid #cdcdcd;
    border-radius: 0 0 0 5px;
    background: linear-gradient(to bottom, #FBFBFB, #F4F4F4 90%);
    padding: 0;
}

tfoot th {
    border-radius: 5px 0 0 5px;
    background: linear-gradient(to top, #FBFBFB, #F4F4F4 90%);
}

thead > tr > td, tfoot > tr > td {
    border-right: 1px solid #fbfbfb;
    border-bottom: 1px solid #cdcdcd;
    padding: 2px 2px 11px;
    background: linear-gradient(to bottom, #FBFBFB, #F4F4F4 90%);
}

tfoot > tr > td {
    padding: 11px 2px 6px;
    background: linear-gradient(to top, #FBFBFB, #F4F4F4 90%);
}

thead > tr > td:last-child, tfoot > tr > td:last-child {
    border-right: 1px solid #cdcdcd;
    border-radius: 0 0 5px;
}

tfoot > tr > td:last-child {
    border-radius: 0 5px 5px 0;
}

thead p:first-child, tfoot p:first-child {
    border-bottom: 1px solid #cdcdcd;
    padding-bottom: 2px;
}

thead p, tfoot p {
    padding-top: 2px;
}

tfoot::before {
    content: '';
    display: block;
    height: 2px;
}

tr:first-child > th:first-child > p:first-child {
    border-radius: 5px 5px 0 0;
}

tr:last-child > th:first-child > p:first-child {
    border-radius: 0 0 5px 5px;
}

tbody th, tfoot th {
    font-weight: normal;
    border-right: 1px solid #e1e1e1;
    text-align: center;
}

tbody tr:nth-child(even) td, tbody tr:nth-child(even) th {
    background: linear-gradient(to top, #FFFFFF, #F9F9F9 90%);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 1) inset;
}

tbody tr:nth-child(odd) td, tbody tr:nth-child(odd) th {
    background: linear-gradient(to top, #f3f3f3, #F9F9F9 90%);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 1) inset;
}

/*
.key {
position:absolute;
	background-image:url(images/key.png);
	background-position:left center;
	background-repeat:no-repeat;
	background-size:10px 5px;
}
*/
tbody tr:nth-child(odd) td.current, tbody tr:nth-child(even) td.current {
    box-shadow: 0 1px 0 #F5F5F5 inset;
    background: #f1f1f1;
}

tbody > tr:first-child > th {
    border-radius: 5px 0 0 0;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
}

tbody > tr:last-child > th {
    border-radius: 0 0 0 5px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

tbody > tr > th {
    border-left: 1px solid #ccc;
    max-height: 45px;
    height: 45px;
    font-size: 9pt;
    text-align: right;
    padding-right: 10px;
}

/*ff issue rel./abs. pos*/

.th {
    display: block;
    position: relative;
}

tbody td, tbody th {
    border-top: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    padding: 1px;
    text-align: center;
}

tbody > tr:first-child > td:last-child {
    border-radius: 0 5px 0 0;
}

tbody > tr:last-child > td:last-child {
    border-radius: 0 0 5px 0;
}

tbody > tr:first-child > td {
    border-top: 1px solid #ccc;
}

tbody > tr:last-child > td {
    border-bottom: 1px solid #ccc;
}

tbody > tr > td:last-child {
    border-right: 1px solid #ccc;
}

tbody::before {
    content: '';
    display: block;
    height: 0;
}

.teamhead > tr > td, .teamhead > tr > th {
    padding: 2px 10px;
    text-align: right;
    height: 0;
}

.teamhead::before {
    content: '';
    display: block;
    height: 1px;
}

.teamhead > tr:last-child > th {
    border-radius: 5px 0 0 5px;
}

.teamhead > tr:last-child > td:last-child {
    border-radius: 0 5px 5px 0;
}

.nn {
    font-size: 9.5pt;
    padding-top: 6px;
}

.vn {
    font-size: 7.5pt;
}

/*
.rl {
font-size : 6pt;
font-weight: bold;
text-align:left;
padding:0 0 0 9px;
color:#a0a0a0;
}
*/
.activeteam {
    font-weight: bold;
    font-size: 7.5pt;
    margin: 0;
}

.inactiveteam {
    font-weight: normal;
    font-size: 7.5pt;
    margin: 0;
}

/*##### thead/tfoot day colors #####*/

.sunday {
    color: #DD2C2C;
}

.sundaydot {
    border-right: 1px dashed #FF7B7B;
}

.saturday {
    color: #000;
}

.current {
    background: #e6e6e6;
    font-weight: bold;
}

/*##### phase #####

.lri {
	background-image:url(images/triangle_right.svg), url(images/triangle_left.svg),url(images/triangle_center.svg);
	background-position:right center, left center, center center;
	background-repeat:no-repeat, no-repeat, no-repeat;
	background-size:auto 55%, auto 55%, 100% 55%;
}	  
*/

/*##### form  #####*/

textarea {
    background: #EDF7FD;
    border: #CCCCCC solid 1px;
    resize: none;
    width: 551px;
    color: #666;
}

.message_wrap {
    margin-bottom: 5px;
}

.form_wrap {
    float: left;
    text-align: left;
}

.button_wrap {
    float: right;
}

.form_row {
    color: #666666;
    padding: 5px 5px;
}

.form_field {
    display: inline-block;
    margin-right: 5px;
}

.form_field label {
    color: #666666;
    display: block;
    text-align: left;
    padding-bottom: 2px;
}

.input_medium {
    width: 180px;
}

.form_input {
    background-color: #EDF7FD;
    border: #CCCCCC solid 1px;
    color: #666;
}

.form_input:focus, textarea:focus {
    background: #FFFBCF;
    border: #999 dotted 1px;
    transition: background-color 0.2s;
}

input:required:invalid, input:focus:invalid {
    background-image: url(images/req.png);
    background-position: right top;
    background-repeat: no-repeat;
    border: 1px solid #d10000;
}

input:required:valid {
}

/*##### push messages  #####*/

#chatlog {
    direction: rtl;
    overflow: hidden;
    background: linear-gradient(to left, #FCFCFC, rgba(255, 255, 255, 0) 50%);
    border-radius: 5px;
    margin-bottom: 3px;
    border: rgba(204, 204, 204, 0) solid 0;
    font-size: 8pt;
    color: #999;
    padding: 2px 4px;
    height: 14px;
    width: 595px;
    float: right;
}

/*##### visible vp #####*/

.vsselect {
    position: absolute;
    left: -21px;
    top: 0;
    background: #ebebeb;
    border: 1px solid #fff;
    padding: 1px;
    height: 45px;
    opacity: 1;
}

.visiblegreen, .visiblered {
    border: 1px solid #7E7E7E;
    border-radius: 11px;
    height: 11px;
    opacity: 0.5;
    padding: 1px;
    top: 1px;
    width: 11px;
    margin: 16px 0 0 0;
    display: block;
}

.on {
    opacity: 0.5;
}

.vsred, .visiblered:hover {
    border-color: red;
    opacity: 1;
}

.vsgreen, .visiblegreen:hover {
    border-color: green;
    opacity: 1;
}

/*##### expand team #####*/

.expand {
    border: 1px solid #7E7E7E;
    border-radius: 11px;
    height: 11px;
    opacity: 0.4;
    padding: 1px;
    top: 1px;
    width: 11px;
    margin-right: 3px;
    float: left;
}

.expand:hover, .visible:hover {
    opacity: 0.8;
}

/*##### statistics #####*/

.statisticsvp {
    font-size: 7.5pt;
    border-top: 1px solid #C6C6C6;
    opacity: 1;
}

.statisticsteam {
    font-size: 7.5pt;
    text-align: right;
    display: inline-block;
    opacity: 1;
}

.chartsteam {
    font-size: 7.5pt;
    display: inline-block;
    float: left;
    position: relative;
    top: -1px;
}

ins {
    text-decoration: none;
}

.grey {
    color: grey;
}

.orange {
    color: orange;
}

.red {
    color: red;
}

.blue {
    color: blue;
}

.green {
    color: green;
}

.black {
    color: black;
}

.greya {
    color: #a0a0a0;
}

ul {
    list-style: none outside none;
    margin: 0;
    padding: 0;
    opacity: 0.5;
}

li {
    display: inline-block;
}

.greyc {
    background: grey;
    height: 4px;
}

.orangec {
    background: orange;
    height: 4px;
}

.redc {
    background: red;
    height: 4px;
}

.bluec {
    background: blue;
    height: 4px;
}

.greenc {
    background: green;
    height: 4px;
}

.greyca {
    background: #bababa;
    height: 4px;
}

.sep {
    height: 4px;
    width: 3px;
}

/*##### loading screen #####*/

.overlay {
    background: url(images/room.jpg);
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
}

.overlay p {
    position: relative;
    top: 30%;
    color: #999;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
}

/*##### container for generated pins #####*/

#topfo1, #topfo2, #topfo3, #topfo4, #topfo5, #topfo6 {
    width: 120px;
    height: 50px;
    position: absolute;
    bottom: 10px;
    border: #ababab dashed 1px;
    border-bottom: 0 none;
    border-radius: 5px 5px 0 0;
    background: linear-gradient(to bottom, #E6E6E6, rgba(244, 244, 244, 0) 90%) 0 0 repeat scroll rgba(0, 0, 0, 0);
}

#topfo1:before, #topfo2:before, #topfo3:before, #topfo4:before, #topfo5:before, #topfo6:before {
    color: #666;
    content: attr(title);
    font-size: 7pt;
    position: absolute;
    top: -15px;
    font-weight: bold;
}

#topfo1 {
    right: 0;
}

#topfo2 {
    right: 125px;
}

#topfo3 {
    right: 250px;
}

#topfo4 {
    right: 375px;
}

#topfo5 {
    right: 500px;
}

#topfo6 {
    right: 625px;
    border-left: 0 none;
}

#topfu1:before, #topfu2:before, #topfu3:before, #topfu4:before, #topfu5:before, #topfu6:before {
    color: #666;
    content: attr(title);
    font-size: 7pt;
    position: absolute;
    bottom: -15px;
    font-weight: bold;
}

#footer {
    margin-top: 20px;
    position: relative;
    height: 30px;
}

#topfu1, #topfu2, #topfu3, #topfu4, #topfu5, #topfu6 {
    width: 195px;
    height: 40px;
    position: absolute;
    border: #ababab dashed 1px;
    border-top: 0 none;
    background: linear-gradient(to top, #ddd, rgba(244, 244, 244, 0) 100%) 0 0 repeat scroll rgba(0, 0, 0, 0);
    border-radius: 0 0 5px 5px;
}

#topfu1 {
    right: 0;
}

#topfu2 {
    right: 201px;
}

#topfu3 {
    right: 402px;
}

#topfu4 {
    right: 603px;
}

#topfu5 {
    right: 804px;
}

#topfu6 {
    right: 1005px;
    border-left: 0 none;
}

/*##### buttons #####*/

.msg {
    background: #E4E3E3;
    border-color: #CCC;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
    font-size: 8pt;
    margin-right: 10px;
    padding: 4px 10px;
    position: relative;
    top: 1px;
}

.msg:hover {
    background: #F4F4F4;
    color: green;
}

/*##### pins big/small #####*/

/*filter: url(images/shadow.svg#drop-shadow);*/

.circle {
    height: 18px;
    width: 18px;
    display: inline-block;
    cursor: pointer;
    margin: 1px;
    border-radius: 9px;
}

.t2 {
    background-image: url(images/orange.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: 0 0;
}

.service {
    background-image: url(images/green.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: 0 0;
}

.t3 {
    background-image: url(images/red.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: 0 0;
}

.tn {
    background-image: url(images/blue.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: 0 0;
}

.t1 {
    background-image: url(images/grey.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: 0 0;
}

span[data-tausgefallen~="ja"] {
    opacity: 0.4;
}

span[data-smaller~="j"] {
    background-position: -1px -1px;
    width: 8px;
    height: 8px;
}

.access {
    background-size: 10px 10px;
    width: 10px;
    height: 10px;
    cursor: url(images/pin_delete.png) 6 0, auto;
}

.small {
    background-size: 10px 10px;
    width: 10px;
    height: 10px;
    cursor: not-allowed;
}

/*##### animations #####*/

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

@keyframes colorfade {
    0% {
        color: green;
    }

    50% {
        color: green;
    }
    100% {
        color: #999;
    }

}

@keyframes opacityChange {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0; /* make things invisible upon start */
    -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;

    -webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-duration: 1s;
    -moz-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

.blink {
    -webkit-animation: blink 1s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation: blink 1s;
    -moz-animation-iteration-count: infinite;
    -o-animation: blink 1s;
    -o-animation-iteration-count: infinite;
    animation: blick 1s;
    animation-iteration-count: infinite;
}

.colorfade {
    -moz-animation: colorfade 5s;
    -moz-animation-iteration-count: 1;
    animation: colorface 5s;
    animation-iteration-count: 1;
    padding: 0 5px;
}

/*##### tooltip #####*/


#ttt {
    position: absolute;
    display: block;
    background: #fff;
    border: #ccc solid 1px;
    padding: 10px;
    opacity: 0.6;
    border-radius: 5px 5px 5px 0;
    font-size: 12px;
}

.tooltip div {
    margin: 0;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
}

.tooltip p {
    margin: 2px 0 8px;
}


/*##### pick year month #####*/

#ym {
    opacity: 0.6;
    padding: 0 10px 0 2px;
    border: #999 solid 0;
    border-right: 1px solid #ccc;
    position: relative;
    top: -4px;
}

#ym:hover {
    opacity: .6;
}

#y, #m {
    line-height: 1;
    outline: 0;
    border-radius: 0;
    background: #FBFBFB;
    font-size: 8pt;
    border: 0 #999;
}

#ymb {
    display: block;
    font-size: 8pt;
    background: none;
    border: none;
    margin-top: 3px;
    width: 100%;
    border-top: 1px solid #b5b5b5;
    text-align: right;
    padding: 2px 0 0;
    cursor: pointer;
}

#ymb:hover {
    color: green;
}
